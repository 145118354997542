import monitor from "./monitor";

import errorReport from "./error";

import performance from "./performance";

(function(win) {
    win.monitor = monitor;
    monitor.report = errorReport;
    monitor.performance = performance;
})(window);
