export function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isFunction(obj) {
    return Object.prototype.toString.call(obj) === '[object Function]';
}

// 获取 uri
export function getUri(uri) {
    if (isFunction(uri)) {
        return uri();
    }
    return uri;
}

// 生成当前页面 pageURI ，排除 url 上的 search 、hash上的 query。 用来标识唯一页面
export function generatePageUri(options = {}) {
    let defaultConfig = {
        origin: true, //是否需要origin
        pathname: true, //是否需要pathname
        search: false, //是否需要search
        hash: true, //是否需要 hash
        hashQuery: false //是否允许hash后面带 ？
    };
    let config = { ...defaultConfig, ...options };
    let location = window.location;
    let pageUri = '';
    ['origin', 'pathname', 'search', 'hash'].forEach((key, index) => {
        if (config[key]) {
            let value = key === 'hash' ? getHash() : location[key];
            pageUri += value || '';
        }
    });

    // 获取页面hash
    function getHash() {
        let hash = location.hash || '';
        if (!config.hashQuery) {
            let splitIndex = hash.indexOf('?');
            if (splitIndex !== -1) {
                hash = hash.substring(0, splitIndex);
            }
        }
        return hash;
    }

    return pageUri;
}

export function noop() {}
