import monitor from "./monitor";

const { config } = monitor;

// 是否支持 timing
function isSupportTiming() {
    return window.performance && window.performance.timing;
}

// 是否支持 performance.getEntriesByType
function isSupportEntries() {
    return window.performance && window.performance.getEntriesByType;
}

function toInt(num) {
    let intNum = parseInt(num);
    return isNaN(intNum) ? 0 : num;
}

// 收集页面加载的性能数据
function collect() {
    let mesure = {};

    if (!isSupportTiming()) {
        return {};
    }

    let timing = performance.timing;

    // DNS 解析耗时
    mesure.dnsLookUp = toInt(timing.domainLookupEnd - timing.domainLookupStart);

    // TCP链接耗时
    mesure.tcpTime = toInt(timing.connectEnd - timing.connectStart);

    // Time to FirstByte（TTFB），网络请求耗时
    // 以 Google Development 定义为准
    mesure.ttfb = toInt(timing.responseStart - timing.requestStart);

    // First Paint Time, 首次渲染时间 / 白屏时间
    // 如果浏览器支持，则会取chrome.loadTimes().firstPaintTime 计算
    // 白屏时间 = 开始渲染时间( 首字节时间+HTML下载完成时间 )+头部资源加载时间,
    // 这种方式忽略了头部资源加载的时间
    mesure.firstPaintTime = toInt(timing.domLoading - timing.navigationStart);

    // 比较准确的白屏时间，需要在body里面打一个点
    if (window.firstPaintEnd) {
        mesure.firstPaintTimeBody = toInt(
            window.firstPaintEnd - timing.navigationStart
        );
    }

    // 数据传输耗时
    mesure.trans = toInt(timing.responseEnd - timing.responseStart);

    // Time to Interact，首次可交互时间
    // 浏览器完成所有HTML 解析并且完成DOM 构建，此时浏览器开始加载资源
    mesure.timeToInteract = toInt(
        timing.domInteractive - timing.navigationStart
    );

    // HTML 加载完成时间， 即 DOM Ready 时间
    // SPA的项目可以当这个为近似于白屏时间
    mesure.domReady = toInt(
        timing.domContentLoadedEventEnd - timing.navigationStart
    );

    // onLoad 事件。 与ready的区别在于此处资源也已经加载完成了
    mesure.onLoad = toInt(timing.loadEventStart - timing.navigationStart);

    // load = 首次渲染时间 + DOM 解析耗时 +同步 JS 执行 + 资源加载耗时 + load方法执行时间耗时
    // mesure.onLoadEnd = timing.loadEventEnd - timing.navigationStart;

    return mesure;
}

// 上报数据
function report(performanceData) {
    const logger = new monitor.Logger(
        `${config.host}`,
        `${config.project}`,
        `${config.performanceLogstore}`
    );
    monitor.reportData(logger, performanceData);
}

// 收集超时资源
function collectTimeOutResource() {
    let timeOutEntrys = [];
    if (isSupportEntries()) {
        let entrys = window.performance.getEntriesByType("resource") || [];
        entrys.forEach(entry => {
            if (isLongTimeResource(entry.duration)) {
                let longTimeEntry = getEntryTiming(entry);
                timeOutEntrys.push(longTimeEntry);
            }
        });
    }
}

// 是否是超时资源
function isLongTimeResource(duration) {
    let timeOut = config.timeOut || 20 * 1000;
    return duration > timeOut;
}

// 计算资源的时间
function getEntryTiming(entry) {
    let times = {};
    // 重定向的时间
    times.redirect = toInt(entry.redirectEnd - entry.redirectStart);

    // DNS 查询时间
    times.dnsLookUp = toInt(entry.domainLookupEnd - entry.domainLookupStart);

    // TCP 建立连接完成握手的时间
    times.tcpTime = toInt(entry.connectEnd - entry.connectStart);

    // 首字节时间
    times.ttfb = toInt(entry.responseStart - entry.requestStart);

    // 内容加载完成时间
    times.trans = toInt(entry.responseEnd - entry.responseStart);

    // 资源名
    times.name = entry.name;

    // 类型
    times.entryType = entry.entryType;

    // 资源总耗时
    // 包括等待时长，请求时长，响应时长
    times.duration = entry.duration;

    // 资源类型
    // 注意，如果图片是在css里面的，则这个值会是css
    times.initiatorType = entry.initiatorType;
    times.encodedBodySize = entry.encodedBodySize;
    times.decodedBodySize = entry.decodedBodySize;

    return times;
}

// 观察资源变化
function observeEntry(callback) {
    if (window.PerformanceObserver) {
        const observer = new PerformanceObserver(list => {
            callback(list);
        });
        observer.observe({
            entryTypes: ["resource"]
        });
    }
}

// 初始化观察资源事件
function initObserveResource() {
    observeEntry(list => {
        list.getEntries().forEach(entry => {
            if (isLongTimeResource(entry.duration)) {
                let longTimeEntry = getEntryTiming(entry);
                report(longTimeEntry);
            }
        });
    });
}

/**
 * performance 需要再onload才能拿到整体的数据，所以在onload之后再上报
 */

monitor.on("load", () => {
    // 监听性能数据
    if (config.autoCollectPerformance) {
        if (isSupportTiming()) {
            let performanceData = collect();
            report(performanceData);
        }
    }

    // 监听超时资源 todo
    // if (isSupportEntries()) {

    //     let longTimeEntrys = collectTimeOutResource();
    //     longTimeEntrys.forEach((entry) => {
    //         report(entry);
    //     })

    //     initObserveResource();
    // }
});

export default {
    collect: collect,
    report: report
};
