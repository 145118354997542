/**
 * Created by laixinyu on 2018/12/26
 **/

import monitor from "./monitor";

const { config } = monitor;

function report(errorMsg) {
    const logger = new monitor.Logger(
        `${config.host}`,
        `${config.project}`,
        `${config.errorLogstore}`
    );
    monitor.reportData(logger, errorMsg);
}

monitor.on("error", res => {
    // message：错误信息（字符串）。Available as event (sic!) in HTML onerror="" handler.
    // source：发生错误的脚本URL（字符串）
    // lineno：发生错误的行号（数字）
    // colno：发生错误的列号（数字）
    // error：Error对象（对象）
    let { error } = res;
    const errorData = {
        level: "error",
        error: "js",
        msg: error ? error.message : "",
        stack: error ? error.stack : ""
    };

    monitor.report(errorData);
});

export default report;
