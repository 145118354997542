/**
 * Created by laixinyu on 2019/1/8
 * 这里是aliyun提供的上报JSSDK，详情或者更新请看：https://help.aliyun.com/document_detail/31752.html
 **/

function createHttpRequest() {
  if (window.ActiveXObject) {
    return new window.ActiveXObject('Microsoft.XMLHTTP');
  }
  else if (window.XMLHttpRequest) {
    return new window.XMLHttpRequest();
  }
}

function AliLogTracker(host, project, logstore) {
  this.uri_         = '//' + project + '.' + host + '/logstores/' + logstore + '/track?APIVersion=0.6.0';
  this.params_      = [];
  this.httpRequest_ = createHttpRequest();
}

AliLogTracker.prototype = {
  push: function(key, value) {
    if (!key || !value) {
      return;
    }
    this.params_.push(key);
    this.params_.push(value);
  },
  logger: function() {
    var url = this.uri_;
    var k   = 0;
    while (this.params_.length > 0) {
      if (k % 2 == 0) {
        url += '&' + encodeURIComponent(this.params_.shift());
      }
      else {
        url += '=' + encodeURIComponent(this.params_.shift());
      }
      ++k;
    }
    try {
      this.httpRequest_.open('GET', url, true);
    //   this.httpRequest_.open('GET', url, false); // 这里改为同步发送，避免后面请求覆盖前面的http请求 https://help.aliyun.com/document_detail/31752.html?spm=a2c4g.11186623.2.26.50de8bdcrmghuF
      this.httpRequest_.send(null);
    }
    catch (ex) {
      if (window && window.console && typeof window.console.log === 'function') {
        console.log('Failed to log to ali log service because of this exception:\n' + ex);
        console.log('Failed log data:', url);
      }
    }

  }
};

export default AliLogTracker;
