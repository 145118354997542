/**
 * Created by laixinyu on 2019/1/8
 **/

import Tracker from './libs/Tracker';
import { isObject, getUri, generatePageUri } from './util';

import baseConfig from './config';

let defaultConfig = {
    ...baseConfig,
    projectName: '', //项目名
    isDebug: false, // 是否调试模式
    autoCollectPerformance: true,
    uri: null, // 页面唯一标识、支持字符串以及 function
    beforeSend: data => data, // 发送前，回调事件，用于在发送前添加一些参数
};

let monitor = {
    version: '__VERSION__',

    config: { ...defaultConfig },

    events: {},

    Logger: Tracker,

    setConfig(key, value) {
        if (isObject(key)) {
            // 支持对象形式
            let options = key;
            for (let i in options) {
                this.config[i] = options[i];
            }
        } else {
            this.config[key] = value;
        }
        this.checkConfig();
    },

    checkConfig() {
        let { isDebug, projectName } = this.config;
        if (isDebug) {
            console.log(`不真实发送日志哦！，isDebug : ${isDebug}`);
        }
        if (!projectName) {
            console.log(`请配置具体项目名 projectName`);
        }
    },

    /**
     * 统一上报数据接口
     * @param {*} logger 上报数据的终端
     * @param {*} pushData 需要上报的数据
     */
    reportData(logger, pushData = {}) {
        let config = this.config;
        const baseData = {
            // 当前路径
            href: document.location.href,
            // 收集用户设备信息
            ua: window.navigator.userAgent,
            // 当前项目名
            projectName: config.projectName,
            // 页面唯一表示
            uri: getUri(config.uri) || ''
        };

        // 触发事件：发送前
        let data = { ...baseData, ...pushData };
        data = config.beforeSend(data);

        let isDebug = this.config.isDebug;
        if (!isDebug) {
            for (let i in data) {
                logger.push(i, data[i] + '');
            }
            logger.logger();
        } else {
            console.log(`logger发送数据：`);
            console.log(data);
        }
    },

    // 订阅事件
    on(event, fn) {
        if (!this.events[event]) {
            this.events[event] = [];
        }

        this.events[event].push(fn);
    },

    // 取消订阅
    off(event, fn) {
        const triggerList = this.events[event];

        const index = triggerList.findIndex(eventFn => {
            return eventFn === fn;
        });

        if (triggerList[index]) {
            triggerList[index] = null;
        } else {
            return false;
        }

        return true;
    },

    // 发布事件
    emit(event, ...args) {
        const triggerList = this.events[event];

        if (triggerList) {
            triggerList.forEach(fn => {
                typeof fn === 'function' && fn(...args);
            });
        }
    },

    // 工具方法，用于生成 PageUri
    generatePageUri
};

if (baseConfig.events.length) {
    baseConfig.events.forEach(event => {
        window.addEventListener(event, target => {
            monitor.emit(event, target);
        });
    });
}

export default monitor;
